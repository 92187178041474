/* eslint-disable import/prefer-default-export */
/* eslint-env browser */
// AuAuth
//
// Common module to handle auth/login/logout of AzureAD in AU.
//

import { getContextFromSession } from './helpers';

class AuAuth {
  config;

  // eslint-disable-next-line max-len
  constructor(clientId, authority, redirectUri, userContextUri, userContextServiceScope, popupErrorPage) {
    this.config = {
      auth: {
        clientId, // Generic Au client id
        authority,
        redirectUri,
        navigateToLoginRequestUrl: true,
        postLogoutRedirectUri: window.location.href,
      },
    };

    this.userContextUri = userContextUri;
    this.userContextServiceScope = userContextServiceScope;
    this.popupErrorPage = popupErrorPage;

    this.msal = new Msal.UserAgentApplication(this.config);

    // Callback used when a token is obtained from the query string
    const authCallback = (err) => {
      if (err && err.errorMessage) {
        console.log(`Token error during login: ${err.errorMessage}`);
      }
    };

    this.msal.handleRedirectCallback(authCallback);
    this.fetching = false;
    this.userContext = null;
    this.fetchEvents = [];
  }

  login() {
    this.msal.loginRedirect({
      authority: this.config.auth.authority,
      state: window.location.href,
    });
  }

  logout() {
    this.msal.logout();
  }

  setUserContext(
    auId = 0,
    key,
    clearCache,
    callback,
    error = () => { },
    setImpersonationSession = true,
    loggedIn = false,
  ) {
    const contextFromSession = getContextFromSession(auId);
    if (contextFromSession && !clearCache) {
      this.userContext = JSON.parse(contextFromSession);
    }
    if (this.userContext != null && typeof callback === 'function' && !clearCache) {
      callback(this.userContext);
      console.log(`Using existing context for key ${key}`);
    } else {
      this.fetchEvents.push({
        key,
        callback,
        error,
      });
      console.log(`Callback for key ${key} added to queue`);
      if (!this.fetching) {
        this.fetching = true;

        if (auId === 0) {
          // eslint-disable-next-line no-param-reassign
          [auId] = this.getUser().auid.match(/(\d+)/g);
        }
        let url = this.userContextUri;
        if (auId > 0) {
          url = `${url}?auid=${auId}`;
        }
        if (clearCache) {
          url = `${url}&clearCache=true`;
        }

        const handleContext = (response, json) => {
          if (response.ok) {
            this.userContext = json;
            if (this.userContext.isStudentImpersonator && setImpersonationSession) {
              window.sessionStorage.setItem('student-impersonation', true);
            }
            // setContextInSession(auId, JSON.stringify(json)); Den fjerner vi altså lige...
          }

          this.fetchEvents.forEach((e) => {
            if (response.ok) {
              if (typeof e.callback === 'function') {
                e.callback(this.userContext);
                console.log(`Callback for key ${e.key} has been invoked`);
              }
            } else {
              e.error(response.status, json);
              console.log('Context fetch errored and error callback with response has been invoked');
            }
          });

          this.fetching = false;
        };

        if (loggedIn) {
          const fetchContext = async () => {
            const response = await fetch(url, {
              credentials: 'include',
            });
            const json = await response.json();
            handleContext(response, json);
          };

          fetchContext();
        } else {
          this.getAccessToken([this.userContextServiceScope]).then(async ({ accessToken }) => {
            const response = await fetch(url, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });

            const json = await response.json();
            handleContext(response, json);
          });
        }
      }
    }
  }

  getUser() {
    const msalUser = this.msal.getAccount();
    if (msalUser == null) {
      return null;
    }
    return {
      name: msalUser.name,
      userId: msalUser.accountIdentifier,
      auid: msalUser.userName,
    };
  }

  getIdToken() {
    return this.msal.acquireTokenSilent({
      scopes: [this.config.auth.clientId],
      authority: this.config.auth.authority,
    });
  }

  getAccessToken(scopes) {
    const req = {
      scopes,
      authority: this.config.auth.authority,
      state: window.location.href,
    };

    const popupFallback = () => {
      // Need to explicitly implement caching, because Msal has chosen to
      // only implement caching when using silent token retrieval.

      // Key similar to how Msal does it internally, except first prop, to avoid collisions.
      const key = JSON.stringify({
        thisIsCachedSafariAccessToken: true,
        authority: req.authority,
        clientId: this.config.auth.clientId,
        scopes: req.scopes,
        homeAccountIdentifier: this.msal.getAccount().homeAccountIdentifier,
      });
      const token = sessionStorage.getItem(key);
      if (token !== null && token !== undefined) {
        const parsed = JSON.parse(token);
        if (parsed.expiresOn && (new Date(parsed.expiresOn) > new Date()) && parsed.accessToken) {
          return new Promise((resolve) => resolve(parsed));
        }
      }

      return this.msal.acquireTokenPopup(req)
        .then((accessToken) => {
          sessionStorage.setItem(key, JSON.stringify(accessToken));
        })
        .catch(() => {
          if (this.popupErrorPage) {
            window.location.href = this.popupErrorPage;
          } else {
            window.alert('This site needs to show popups, but your browser blocks popups from this site.\nYou need to allow popups from this site, then reload the page.');
          }
        });
    };

    // Safari desktop fallback to popups because of eradic Safari bugs using silent/iframe.
    if (typeof window.safari !== 'undefined') {
      return popupFallback();
    }

    return this.msal.acquireTokenSilent(req)
      .catch(() => popupFallback());
  }
}

// Export as singleton instance
window.auAuth = new AuAuth(
  window.auAuthClientId,
  window.auAuthAuthority,
  window.auAuthRedirectUri,
  window.userContextApiUri,
  window.userContextServiceScope,
  window.popupErrorPage,
);
